body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Igra Sans";
  src: url("./ui/fonts/IgraSans.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

p {
  margin: 0;
}

h2 {
  margin: 0;
}

h3 {
  margin: 0;
}

h4 {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
